//in src/templates/noteTemplate.js

import React from "react"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from '../../components/Layout';
// import { InstantSearch, SearchBox, Hits, Stats } from "react-instantsearch-dom";
// import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";

// const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
//   server: {
//     apiKey: "Dhx3CELT0VisYPUJzVChkem1pJXWCDXl", // Be sure to use an API key that only allows search operations
//     nodes: [
//       {
//         host: "k7dfh0nzpb3uem5qp.a1.typesense.net",
//         protocol: "https",
//       },
//     ],
//     cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
//   },
//   // The following parameters are directly passed to Typesense's search API endpoint.
//   //  So you can pass any parameters supported by the search endpoint below.
//   //  query_by is required.
//   additionalSearchParameters: {
//     query_by: "title, description, tags",
//   },
// });
// const searchClient = typesenseInstantsearchAdapter.searchClient;


export default function noteTemplate({ data }) {
  const { mdx } = data

  return (
    <Layout>
      {/* <InstantSearch indexName="pages_v1_1666690780887" searchClient={searchClient}>
        <SearchBox />
        <Hits />
      </InstantSearch> */}
      <article>
        <h1 data-typesense-field="title">{mdx.slug}</h1>
        <MDXRenderer>{mdx.body}</MDXRenderer>
        <h3>{mdx.inboundReferences.length > 0 ? <p>Referenced in:</p> : ""}</h3>
        <ul>
          {mdx.inboundReferences.map(ref => (
            <li key={ref.slug}>
              <Link key={ref.slug} to={`/notes/${ref.slug}`}>{ref.slug}</Link>
            </li>
          ))}
        </ul>
        <Link to="/notes">All notes</Link>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    mdx(slug: { eq: $slug }) {
      body
      inboundReferences {
        ... on Mdx {
          id
          slug
        }
      }
    }
  }
`